<template>
  <form @submit.prevent="saveProduct">
    <loading v-if="!isAdd && !product" />

    <div v-else>
      <b-field label="Active">
        <b-switch v-model="form.isActive" :disabled="processing" />
      </b-field>

      <b-field label="Type">
        <b-select v-model="form.type" :disabled="processing" expanded>
          <option v-for="type in types" :key="type.value" :value="type.value">
            {{ type.label }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Name">
        <b-input
          v-model="form.name"
          placeholder="Enter product name"
          :disabled="processing"
        />
      </b-field>

      <b-field label="Description">
        <textarea-autosize
          v-model="form.description"
          placeholder="Please type product description here"
          rows="1"
          class="textarea has-margin-bottom-50"
          :min-height="30"
          :max-height="200"
          autosize
          :disabled="processing"
        />
      </b-field>
    </div>
  </form>
</template>

<script>
import { deleteField, doc, serverTimestamp, setDoc } from "@firebase/firestore";
export default {
  name: "ProductDetails",
  props: {
    productId: {
      type: String,
      required: false,
      default: ""
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      fields: ["dateCreated", "description", "isActive", "name", "type"],
      form: {
        name: "",
        description: "",
        isActive: true,
        type: "subscription"
      },
      types: [
        {
          label: "Subscription",
          value: "subscription"
        },
        {
          label: "Task credit",
          value: "taskCredit"
        }
      ]
    };
  },
  computed: {
    product() {
      return this.$store.getters["products/product"](this.productId);
    },
    isValid() {
      if (this.$_.isEmpty(this.form.name)) return false;
      if (this.$_.isEmpty(this.form.description)) return false;
      return true;
    },
    isSubscription() {
      return this.form.type === "subscription";
    },
    hasChange() {
      return !this.$_.isEqual(
        this.$_.omit(this.form, ["provision"]),
        this.$_.omit(this.product, ["_id", "provision"])
      );
    }
  },
  watch: {
    processing(newVal) {
      this.$emit("processing", newVal);
    },
    isValid(newVal) {
      this.$emit("valid", newVal);
    },
    loading(newVal) {
      this.$emit("loading", newVal);
    },
    hasChange: {
      handler(newVal) {
        this.$emit("hasChange", newVal);
      },
      immediate: true
    },
    product: {
      handler(newVal) {
        if (newVal) {
          this.form = this.$_.pick(this.$_.cloneDeep(newVal), this.fields);
        }
      },
      immediate: true
    },
    "form.type"(newType) {
      this.$emit("typeChanged", newType);
    }
  },
  created() {
    this.$store.dispatch("products/observeProduct", {
      productId: this.productId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("products/unobserveProduct", {
      productId: this.productId
    });
  },
  methods: {
    saveProduct() {
      if (!this.isValid) return;

      this.processing = true;
      let form = this.$_.omit(this.form, ["provision"]);

      if (form.type === "taskCredit") {
        if (this.$_.get(this.product, "provision", null))
          form.provision = deleteField();
      } else if (form.type === "subscription") {
        if (!this.$_.get(this.product, "provision", null)) form.provision = {};
      }

      if (!this.product) {
        form.dateCreated = serverTimestamp();
      }

      let productRef = doc(this.$firestore, "products", this.productId);

      setDoc(productRef, form, { merge: true })
        .then(() => {
          this.processing = false;
          this.$emit("success");
          this.$toast.open({
            message: `Product saved`,
            position: "is-bottom",
            queue: false,
            type: "is-success"
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error saving product`,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
